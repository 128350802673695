
import setBasic from './components/setBasic'

export default {
    name: 'Set',
    components: {
        setBasic,
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: true,
            hasLogin: false,
            userInfo:{}
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    beforeRouteEnter (to, from, next) {
        if(['Country', 'LangPage', 'Currency'].includes(from.name)) {
            location.reload()
        }
        next()
    },
    created() {
        this.hasLogin = this.$login()
    },
    mounted() {
        this.initialSet()
    },
    methods: {
        handleLogin() {
            this.hasLogin = this.$login()
            if(!this.hasLogin) {
                this.$router.push({
                    name: 'Login'
                })
            } else {
                this.$Dialog.confirm({
                    message: this.$translate('Are you sure to sign out?'),
                    confirmButtonText: this.$translate('Sign Out'),
                    cancelButtonText: this.$translate('Cancel'),
                    cancelButtonColor: '#333',
                    confirmButtonColor: '#333',
                }).then(() => {
                    this.removeData()
                    this.$router.push({
                        name: 'Mine'
                    })
                }).catch(() => {});
            }
        },
        // 清除缓存
        removeData() {
            this.$storage.remove('userId')
            this.$storage.remove('token')
            const keyArr = [
                'order/billAddress',
                'order/cardIcon',
                'order/cardPayInfo',
                'order/coupon',
                'order/payMethod',
                'order/placeAddress',
                'order/placeProduct',
                'order/placeGiftCard',
                'order/placeGiftCardParams',
                'order/placePage',
                'order/remarks',
                'order/shippingMethod'];
            keyArr.forEach(key => {
                this.$storage.remove(key)
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Settings'),
                keywords: this.$translate('Settings'),
                description: this.$translate('Settings'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        initialSet() {
            let clear = setTimeout(() => {
                this.skeleton = false
                clearTimeout(clear)
            }, 200)
            let userId = this.$storage.get('userId');
            if(this.hasLogin&&userId){
                this.$api.user.getUseInfo({
                    id: userId
                }).then(response => {
                    const res = response.result || {};
                    this.userInfo = res || {}
                })
            }
        }
    },
}
